import React, { useState, useEffect } from "react";
import PageTitle from "./PageTitel/Pagetitle";
import Getstarted from "./Getstarted";
import { BsFillTelephoneInboundFill } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr'
import apiService from "../Baseurl/apiService";
import ContentDialog from "../DialogBox/ContentDialog";
import InputField from '../InputField';
import { IoLocationSharp } from "react-icons/io5";

function ContactusForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [fix, setFix] = useState(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [messageError, setMessageError] = useState('');


    const Navstick = () => {
        if (window.scrollY >= 30) {
            setFix(true);
        } else {
            setFix(false);
        }
    };
    const closeDialog = () => {
        setIsSuccessDialog(false);
        // refresh page
        window.location.reload();
        
    };

    useEffect(() => {
        window.addEventListener("scroll", Navstick);
        return () => {
            window.removeEventListener("scroll", Navstick);
        };
    }, []);

    const validateFields = () => {
        const isFirstNameValid = firstName.trim() !== '';
        const isLastNameValid = lastName.trim() !== '';
        const isEmailValid = email.trim() !== '' && /\S+@\S+\.\S+/.test(email);
        const isPhoneNumberValid = /^[0-9]{10}$/.test(phoneNumber);
        const isMessageValid = message.trim() !== '';
        if (!isFirstNameValid) {
            setFirstNameError('Enter first name');
        } else {
            setFirstNameError('');
        }
        if (!isLastNameValid) {
            setLastNameError('Enter last name');
        } else {
            setLastNameError('');
        }
        if (!isEmailValid) {
            setEmailError('Enter valid email');
        } else {
            setEmailError('');
        }
        if (!isPhoneNumberValid) {
            setPhoneNumberError('Enter valid phone number');
        } else {
            setPhoneNumberError('');
        }
        if (!isMessageValid) {
            setMessageError('Enter message');
        } else {
            setMessageError('');
        }

        setIsValid(isFirstNameValid && isLastNameValid && isEmailValid && isPhoneNumberValid && isMessageValid);
        return isFirstNameValid && isLastNameValid && isEmailValid && isPhoneNumberValid && isMessageValid;
    }
    const callContactUsApi = async () => {
        setIsLoadingDialog(true);
        await apiService.post('/faq/contact-us', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            message: message
        }).then((res) => {
            var data = res.data;
            setIsLoadingDialog(false);
            setIsSuccessDialog(true);
            // clear form
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');

        }
        ).catch((err) => {
            console.log(err);
            setIsLoadingDialog(false);
        })

    };

    const handleSubmit = () => {
        console.log("submit");
        var isValid = validateFields();
        if (isValid) {
            // Perform form submission logic here
            console.log('Form submitted successfully');
            callContactUsApi();
        }
    }

    return (
        <>
            <div
                className={fix ? "w-full  bg-white" : " bg-white"}
            >
                <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
                    }`}>
                    <PageTitle
                        activeMenu="Contact Us"
                    />
                </div>
            </div>
            <div className="mb-14">
                <div className=" bg-sky-50 bg-cover bg-no-repeat w-full ">
                    <div className="mx-auto container mb-14 w-full flex items-center justify-center pt-6">
                        <div className="bg-white shadow-xl rounded-xl py-10 lg:px-28 px-8">
                            <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">Connect with us</p>
                            <div className="md:flex items-center mt-12">
                                <div className="md:w-72 flex flex-col">

                                    <InputField
                                        id="firstName"
                                        label="First Name"
                                        type="text"
                                        placeholder="Please input first name"
                                        value={firstName}
                                        onChange={(event) => setFirstName(event)}
                                        errorText={firstNameError}
                                    />

                                </div>
                                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                                    <InputField
                                        id="lastName"
                                        label="Last Name"
                                        type="text"
                                        placeholder="Please input last name"
                                        value={lastName}
                                        onChange={(event) => setLastName(event)}
                                        errorText={lastNameError}
                                    />
                                </div>
                            </div>
                            <div className="md:flex items-center mt-8">
                                <div className="md:w-72 flex flex-col">
                                    <InputField
                                        id="email"
                                        label="Email"
                                        type="email"
                                        placeholder="Please input email"
                                        value={email}
                                        onChange={(event) => setEmail(event)}
                                        errorText={emailError}
                                    />


                                </div>
                                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                                    <InputField
                                        id="phoneNumber"
                                        label="Phone Number"
                                        type="number"
                                        placeholder="Please input phone number"
                                        value={phoneNumber}
                                        onChange={(event) => setPhoneNumber(event)}
                                        errorText={phoneNumberError}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="w-full flex flex-col mt-8">
                                    <label htmlFor="message" className="text-base font-semibold leading-none text-gray-800">Message</label>
                                    <textarea
                                        tabIndex={0}
                                        id="message"
                                        aria-label="Leave a message"
                                        role="textbox"
                                        className="h-16 text-base leading-none text-gray-900 p-3 focus:outline-none focus:border-[#0E7cF2] mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 resize-none"
                                        value={message}
                                        onChange={(event) => setMessage(event.target.value)}
                                    />
                                    {messageError && <p className="text-sm text-red-500">{messageError}</p>}
                                </div>
                            </div>
                            <p className="leading-3 text-[#757474]  text-xs mt-4">By clicking submit you agree to our terms of service, privacy policy and how we use data as stated</p>
                            <div className="flex items-center justify-center w-full">
                                <button
                                    className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 buttonbg rounded hover:bg-blue-700"
                                    onClick={handleSubmit}

                                >
                                    Submit Form
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pb-8 ">
                        <h1 className="text-3xl font-semibold mb-6">Reach out to us!</h1>
                        <div className="lg:flex lg:justify-center md:flex md:justify-center space-x-5 mt-5  ">
                            <span className='lg:text-start text-center '>
                                <h2 className="text-lg font-normal">Contact our toll-free number :</h2>
                                <span className='flex lg:justify-start justify-center items-center space-x-1'>
                                    <BsFillTelephoneInboundFill className='text-lg font-medium text-[#0e7cf4]' />
                                    <p className="text-lg font-medium  text-[#0e7cf4]">+91 9258712434</p>
                                </span>
                                <p className="text-sm font-light  lg:w-56">(Monday to Friday, 9 am to 6 pm excluding public holidays)</p>
                            </span>
                            <span>
                                <div class="border-l border-dotted border-gray-500 h-24 hidden lg:block md:block "></div>
                            </span>
                            <span className='lg:text-start text-center '>
                                <h2 className="text-lg font-normal">Get in touch through email :</h2>
                                <span className='flex items-center justify-center space-x-1'>
                                    <GrMail className='text-lg font-medium text-[#0e7cf4]' />
                                    <p className="text-lg font-medium text-[#0e7cf4]">feedback@apnabillbook.com</p>
                                </span>
                                <p className="text-sm font-light">(Anytime 24x7)</p>
                            </span>
                            <span>
                                <div class="border-l border-dotted border-gray-500 h-24 hidden lg:block md:block "></div>
                            </span>
                            <span className='lg:text-start text-center '>
                                <h2 className="text-lg font-normal">Operational Address :</h2>
                                <span className='flex space-x-1 justify-center'>
                                    <IoLocationSharp  className='text-xl mt-1  font-medium text-[#0e7cf4]' />
                                    <h1 className="text-lg font-medium text-[#0e7cf4] lg:hidden md:hidden">183,Shanti Bhawan, Kukra Road, Mahadev Property Dealer,<br/> Gandhi Nagar,Muzaffarnagar, Uttar Pradesh, 251001</h1>
                                    <h1 className="text-lg font-medium text-[#0e7cf4] hidden md:block lg:block">183,Shanti Bhawan, Kukra Road, <br/>Mahadev Property Dealer,Gandhi Nagar,<br/> Muzaffarnagar, Uttar Pradesh, 251001</h1>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <ContentDialog
                isOpen={isSuccessDialog}
                onClose={closeDialog}
                title={"Thank you for contacting us. We will get back to you soon"}
            />
            <Getstarted />


        </>
    );
}

export default ContactusForm;

import React, { useEffect, useState } from 'react'
import QRCodeScanner from '../QRcode/Qrcode'
import Getlink from '../AppgetLinkdownload/Getlink';
import CustomizedInputBase from '../AppgetLinkdownload/ApnaWhatsappField';
import Googleplay from '../Buttom/Googleplay';

const Getstarted = () => {
    const [haveError, setError] = useState(false);
    const [mobile, setMobile] = useState('');

    // on load
    window.addEventListener('resize', () => {
        setMobile(window.innerWidth <= 768);
    });


    useEffect(() => {
        // get screen is mobile or not
        const isMobile = () => {
            const ua = navigator.userAgent;
            return /Android|Mobi/i.test(ua);
        };
        if (isMobile()) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile])
    return (
        <>
            <div className="mb-5 xl:px-20 px-6 mt-10">
                <div className='border border-gray-500 rounded-xl '>
                    <div className="mx-auto xl:px-10 px-5 container xl:flex lg:justify-between  md:grid grid-cols-2 lg:py-12 md:py-4 py-5  space-x-5">
                        <div className=" justify-start ">
                            <h1 className=' text-black lg:text-4xl leading-tight  text-3xl font-[600] lg:text-start  
                            mb-2 md:text-start text-center text-[20px]'>Get Started With Apna Billbook.</h1>
                            <p className=' lg:text-[18px] text-[#575757] text-sm lg:text-start md:text-start text-center'>
                                Give your business an extra touch of professionalism.</p>
                            <div className="mt-6">

                                <CustomizedInputBase setError={setError} small={mobile} />
                            </div>
                            <div className='mt-5 text-[10px] xl:justify-start flex lg:text-start lg:justify-center text-center'>
                                By continuing, I agree to receive WhatsApp communication from Apna Billbook.
                            </div>
                            {
                                haveError ?
                                    <div className='text-red-500 lg:text-sm lg:justify-start flex mt-5 justify-center'>
                                        Please enter a valid mobile number
                                    </div>
                                    : null
                            }
                            <div className=" flex justify-center mt-6 lg:mb-0 mb-10
     " >
                                <Googleplay />
                            </div>
                        </div>
                        <div className="">
                            <QRCodeScanner />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Getstarted
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function addMetaData() {
  const title = "Apna Billbook - Best Shop & Bill Management App for Small Retailers and Restaurants";
  const description = "Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking.";
  const ogImage = "https://apnabillbook.com/twitter.jpg";
  const ogURL = "https://apnabillbook.com/";
  const twitterImage = "./twitter.jpg";
  // Set the title
  document.title = title;

  const title65Char = title.substring(0, 65) ?? title;
  const description155Char = description.substring(0, 155) ?? description;

  // Set the meta tags
  document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
  document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
  document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
  document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
  document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
  document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
  document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
  document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
  document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

}
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
  addMetaData()
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

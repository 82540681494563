import React from 'react'

const ContentDialog = ({ isOpen, onClose, title }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center shadow-lg">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative z-10  rounded-lg p-8">
                <button
                    onClick={onClose}
                    className="absolute top-0 lg:right-0 right-7 text-white bg-black p-1 shadow-lg rounded-md"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                <div className="">
                    <div className="bg-white py-10 px-3 w-full rounded-lg">
                        <div
                            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-[#49C95A]"
                        >
                            <svg
                                class="h-12 w-12 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                ></path>
                            </svg>
                        </div>

                        <div class="mt-6 px-10">
                            <p class="text-sm text-gray-500 text-center">
                                {title}
                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContentDialog;
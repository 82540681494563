import React, { useState } from 'react'
import logo from '../../Assest/logoimage/logo_blue.png'
import { SlSocialLinkedin } from 'react-icons/sl'
import { BsInstagram, BsYoutube } from 'react-icons/bs'
import { FiFacebook, FiTwitter } from 'react-icons/fi'
import { GrFacebookOption, GrLinkedin, GrYoutube } from "react-icons/gr";
import { TbBrandTwitter } from "react-icons/tb";
import { IoLogoInstagram } from "react-icons/io";
import { BsLinkedin } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Getlink from '../AppgetLinkdownload/ApnaWhatsappField'
import { SiLinkedin } from 'react-icons/si'

const Footer = () => {

    return (
        <>
            <div className="bg-sky-50">
                <footer
                    class=" xl:px-14 px-6  container mx-auto lg:text-left">
                    <div class="py-10  md:text-left">
                        <div class="grid-1 grid md:grid-cols-3 gap-4   xl:grid-cols-4">
                            <div class="hidden md:block">
                                <div
                                    class="mb-4">
                                    <img src={logo} alt="" className='w-28' />
                                </div>
                                <h1 className=' text-[#575757]'>
                                    Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking. </h1>
                                <div className=" mt-5">
                                    <div class="hidden md:block">
                                        <div class=" ">
                                            <div class="">
                                                <div class="md:flex">
                                                    <div class="xl:w-full ">
                                                        <Getlink small={true}
                                                            setError={() => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="lg:hidden md:hidden text-center">
                                <div
                                    class="mb-4 flex justify-center">
                                    <img src={logo} alt="" className='w-28' />
                                </div>
                                <h1 className='text-center text-[#575757]'>
                                    Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking. </h1>

                                <div className=" mt-5">
                                    <div class="hidden md:block">
                                        <div class=" ">
                                            <div class="">
                                                <div class="md:flex">
                                                    <div class="xl:w-full ">
                                                        <Getlink small={true}
                                                            setError={{}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:hidden  md:hidden">
                                    <span className="flex justify-center space-x-4
                                     items-center   pt-4 pb-4">
                                        <Link to="https://www.facebook.com/apnabillbook" target="_blank" rel="noreferrer">
                                            <GrFacebookOption className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></Link>
                                        <Link to="https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A" target="_blank">
                                            <GrYoutube className=" text rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                        </Link>
                                        <Link to="https://www.instagram.com/apnabillbook/" target="_blank" rel="noreferrer">
                                            <IoLogoInstagram className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></Link>
                                        <Link to="https://www.linkedin.com/company/apna-softwares/about/" target="_blank">
                                            <SiLinkedin className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <div class="lg:ml-20 md:block hidden ml-6">

                                <h1 class="mb-4">
                                    <Link to="/feature" class="text-[#575757] "
                                    >Features</Link
                                    >
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/feature/online-store" class="text-[#575757] "
                                    >Online Store</Link
                                    >
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/feature/inventory-management" class="text-[#575757] "
                                    >Inventory Management</Link
                                    >
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/feature/customer-management" class="text-[#575757] "
                                    >Customer Management</Link
                                    >
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/feature/expense-management" class="text-[#575757] "
                                    >Expense Management</Link
                                    >
                                </h1>
                            </div>
                            <div class="lg:ml-28  md:block hidden ">

                                <h1 className='mb-4'>
                                    <Link to="/privacy-policy" class="text-[#575757]"
                                    >Privacy Policy</Link>
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/terms-conditions" class="text-[#575757]"
                                    >Terms & Conditions</Link>
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/blog" class="text-[#575757]"
                                    >Blogs</Link>
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/faq" class="text-[#575757]"
                                    >FAQ's</Link>
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/privacy-policy" class="text-[#575757]"
                                    >Security Notice</Link>
                                </h1>
                                <h1 class="mb-4">
                                    <Link to="/refund-policy" class="text-[#575757]"
                                    >Refund Policy</Link>
                                </h1>

                            </div>
                            {/* responsive */}
                            <div className="lg:hidden md:hidden grid grid-cols-2 gap-2">
                                <div class=" ">

                                    <h1 class="mb-4">
                                        <Link to="#!" class="text-[#575757] "
                                        >Features</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="#!" class="text-[#575757] "
                                        >Online Store</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/blog" class="text-[#575757] "
                                        >Blogs</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/faq" class="text-[#575757] "
                                        >FAQ's</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/" class="text-[#575757] "
                                        >Security Notice</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/refund-policy" class="text-[#575757]"
                                        >Refund Policy</Link>
                                    </h1>
                                </div>
                                <div class="">
                                    <h1 className='mb-4'>
                                        <Link to="/privacy-policy" class="text-[#575757]"
                                        >Privacy Policy</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/terms-conditions" class="text-[#575757]"
                                        >Terms & Conditions</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/" class="text-[#575757]"
                                        >Inventory Management</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/" class="text-[#575757]"
                                        >Customer Management</Link>
                                    </h1>
                                    <h1 class="mb-4">
                                        <Link to="/" class="text-[#575757]"
                                        >Expense Management </Link>
                                    </h1>

                                </div>
                            </div>
                            <div className="hidden lg:block md:block xl:ml-16 md:ml-0">
                                <span className="flex justify-start  space-x-4
                                     items-center   pt-4 pb-4">
                                    <Link to="https://www.facebook.com/apnabillbook" target="_blank" rel="noreferrer">
                                        <GrFacebookOption className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </Link>
                                    <Link to="https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A">
                                        <GrYoutube className=" text rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </Link>
                                    <Link to="https://www.instagram.com/apnabillbook/" target="_blank" rel="noreferrer">
                                        <IoLogoInstagram className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></Link>
                                    <Link to="https://www.linkedin.com/company/apna-softwares/about/">
                                        <SiLinkedin className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </Link>
                                </span>
                            </div>

                        </div>
                    </div>

                </footer>
                <div class="buttonbg p-6 text-center text-[16px] text-white">
                    <span>Copyright &copy; 2023 all rights reserved Apna Softwares</span>

                </div>
            </div>
        </>
    )
}

export default Footer
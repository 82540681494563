import React from "react";
import pic from "../../Assest/logoimage/logo_blue.png";
import pic1 from "../../Assest/logoimage/logo_white.png"
import Download from "../Buttom/Download"
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import {
  MdOutlineClose,
} from "react-icons/md";
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { BiSolidContact } from "react-icons/bi"
import { FaBlog, FaHome } from "react-icons/fa"

const Nav = () => {
  const navigate = useNavigate();
  const [fix, setFix] = useState(false);
  const [menu, setMenu] = useState(false);
  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  window.addEventListener("scroll", Navstick);

  return (
    <>
      <div className="">
        <div className={fix ? "w-full  nav-image" : " nav-image "} id="home">
          <div
            className={` w-full  shadow-2xl ${fix ? "fixed bg-white top-0  z-50" : "nav-image  text-white "
              }`}
          >
            <div className="lg:px-20 md:px-8 px-5 py-3">
              <div className="flex justify-between items-center space-x-10  ">
                {!fix ? <NavLink to="/">
                  <img src={pic1} alt="" className="w-32"
                  />
                </NavLink> : <NavLink to="/">
                  <img src={pic} alt="" className="w-28"
                  />
                </NavLink>}
                <nav className="flex items-center space-x-10 font-sans text-2xl font-semibold">
                  <ul className="md:flex hidden items-center space-x-16  tracking-wide text-lg cursor-pointer">
                    {fix ?
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-[#0E7CF4]" : ""
                        }
                        to="/"
                      >
                        Home
                      </NavLink> : <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-white" : ""
                        }
                        to="/"
                      >
                        Home
                      </NavLink>
                    }
                    {fix ?
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-[#0E7CF4]" : ""
                        }
                        to="/feature"
                      >
                        Feature
                      </NavLink> : <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-white" : ""
                        }
                        to="/feature"
                      >
                        Feature
                      </NavLink>
                    }

                    {fix ?
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-[#0E7CF4]" : ""
                        }
                        to="/contact-us"
                      >
                        Contact Us
                      </NavLink> : <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-white" : ""
                        }
                        to="/contact-us"
                      >
                        Contact Us
                      </NavLink>
                    }
                    {fix ?
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-[#0E7CF4]" : ""
                        }
                        to="/blog"
                      >
                        Blog
                      </NavLink> : <NavLink
                        className={({ isActive }) =>
                          isActive ? "border-b border-white" : ""
                        }
                        to="/blog"
                      >
                        Blog
                      </NavLink>
                    }

                  </ul>
                  {!fix ?
                    <NavLink className="md:hidden  " >
                      <button onClick={() => setMenu(!menu)} className="text">
                        <AiOutlineMenu className="text-[2.5rem] mr-3 text-white" />
                      </button>
                    </NavLink> :
                    <NavLink className="md:hidden ">
                      <button onClick={() => setMenu(!menu)} className="text">
                        <AiOutlineMenu className="text-[2.5rem] mr-3 text-[#0E7CF4]" />
                      </button>
                    </NavLink>}
                </nav>
                <div className="hidden xl:block">
                  <Download />
                </div>
              </div>
            </div>
          </div>
          {/* SideBar Mobile Menu -----------------------------------------------------*/}

          <div
            className={
              menu
                ? "w-full duration-300 border-r transition-all ease-in-out lg:hidden bg-white z-50  fixed top-0 "
                : "-left-72 fixed duration-100 transition-all"
            }
            id="sidenavExample"
          >
            <div className="flex justify-between items-center p-3 border-b">
              <img className="md:w-32 w-[7.3rem] " src={pic} alt="" />
              <MdOutlineClose
                onClick={() => setMenu(!menu)}
                className="text-black text-2xl cursor-pointer absolute top-4 right-4  "
              />
            </div>

            <ul className="relative space-y-3 mt-5 font-sans text-2xl leading-7 font-semibold">
              <li
                onClick={() => {
                  navigate("/");
                  setMenu(!menu);
                }}
                className="relative "
                id="sidenavEx1"
              >
                <h3
                  className="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="dark"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSidenavEx1"
                  // aria-expanded="true"
                  aria-controls="collapseSidenavEx1"
                >
                  <FaHome className="text-2xl " />
                  <span className="text-base font-medium">Home</span>
                </h3>
              </li>
              {/* Features */}

              <li
                onClick={() => {
                  navigate("/feature");
                  setMenu(!menu);
                }}
                className="relative"
                id="sidenavEx3"
              >
                <h3
                  className="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="dark"
                >

                  <FeaturedPlayListIcon />

                  <span className="text-base font-medium">Features</span>
                </h3>
              </li>
              {/* Features */}
              <li
                onClick={() => {
                  navigate("/contact-us");
                  setMenu(!menu);
                }}
                className="relative"
                id="sidenavEx3"
              >
                <h3
                  className="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="dark"
                >
                  <BiSolidContact className="text-2xl text-black " />
                  <span className="text-base font-medium">Contact Us</span>
                </h3>
              </li>
              <li
                onClick={() => {
                  navigate("/blog");
                  setMenu(!menu);
                }}
                className="relative"
                id="sidenavEx3"
              >
                <h3
                  className="flex items-center space-x-5 text-sm py-4 px-6 h-12 overflow-hidden text-black text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="dark"
                >
                  <FaBlog className="text-2xl text-black " />
                  <span className="text-base font-medium"> Blog</span>
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
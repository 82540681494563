import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Nav from './Component/Header/NavBar';
import Homepage from './Homepage';
import Footer from './Component/Footer/Footer';
import ScrollToTop from './Component/scrollTop/ScrollToTop';
import { FaWhatsapp } from 'react-icons/fa';
import ContactusForm from './Component/Section/ContactusForm';
import Privacy from './Component/Section/Privacy';
import Termsandcondtion from './Component/Section/Termsandcondtion';
import BlogPost from './Component/Blog/Blog';
import PageTop from './Component/scrollTop/PageTop';
import BlogContent from './Component/Blog/Blogcontent';
import { BlogProvider } from './Component/Blog/BlogContext';
import CreateBlog from './Component/Blog/CreateBlog';
import FaqContent from './Component/Faq/FaqContent';
import { BiLogoFacebook } from 'react-icons/bi'
import Features from './Component/Features/Features';
import DownloadButton from './Component/DownloadButton/DownloadButton';
import RefundPolicy from './Component/Section/RefundPolicy';

// Define a custom 404 component
const NotFound = () => {
  return (
    <div class="text-center my-20">
      <h1 class="mb-4 text-6xl font-semibold text-red-500">404</h1>
      <p class="mb-4 text-lg text-gray-600">Oops! Looks like you're lost.</p>
      <div class="animate-bounce">
        <svg class="mx-auto h-16 w-16 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
        </svg>
      </div>
      <p class="mt-4 text-gray-600">Let's get you back <a href="/" class="text-blue-500">home</a>.</p>
    </div>


  );
};
const App = () => {
  const [showButton, setShowButton] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [playHovered, setPlayHovered] = useState(false);
  const [facebookHovered, setFacebookHovered] = useState(false);
  const [youTubeHovered, setYouTubeHovered] = useState(false);

  const youtubeLink = 'https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A';
  const facebookLink = 'https://www.facebook.com/apnabillbook';
  const instagramLink = 'https://www.instagram.com/apnabillbook/';
  const linkedinLink = 'https://www.linkedin.com/company/apna-softwares/about/';
  const whatsappLink= () => {
    const phoneNumber = '+919258712434'; 
    const message = 'Welcome to Apna Billbook!'; 
    const encodedMessage = encodeURIComponent(message); 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
};


  const playstoreLink = 'https://play.google.com/store/apps/details?id=app.apnabillbook.com';


  function addMetaData() {
    const title = "Apna Billbook - Best Shop & Bill Management App for Small Retailers and Restaurants";
    const description = "Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking.";
    const ogImage = "https://apnabillbook.com/twitter.jpg";
    const ogURL = "https://apnabillbook.com/";
    const twitterImage = "./twitter.jpg";
    // Set the title
    document.title = title;

    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = description.substring(0, 155) ?? description;

    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    addMetaData()
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BlogProvider>
      <Nav />
      <PageTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact-us" element={<ContactusForm />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Termsandcondtion />} />
        <Route path="/blog" element={

          <BlogPost />


        } />
        <Route path="/blog-post/:title" element={

          <BlogContent />

        } />
        <Route path="/create-blog" element={<CreateBlog />} />
        <Route path="/faq" element={<FaqContent title="FAQ's" />} />
        <Route path="/feature" element={<Features />} />
        <Route path="/feature/:title" element={<Features />} />
        {/* Define a catch-all route for 404 errors */}
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/download-button" element={<DownloadButton />} /> */}
        <Route path="/refund-policy" element={<RefundPolicy />} />
      </Routes>
      <div className='hidden lg:block md:block xl:block'>
        <div
          className={`contact-button-group  ${hovered ? 'hovered' : ''}`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {showButton && (
            <div>
              <div
                onClick={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="contact-button rounded-l-full py-2 px-5 "
              >
                <FaWhatsapp className="whatsapp-icon text-white" />

                <button className="contact-button-text text-2xl ">Contact Us</button>

              </div>

            </div>
          )}
        </div>
        <div
          className={`contact-button-playstore  ${playHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setPlayHovered(true)}
          onMouseLeave={() => setPlayHovered(false)}
        >
          {showButton && (
            <div>

              <a
                href={playstoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-l-full items-center justify-center">
                  <div class="mr-4 ">
                    <svg viewBox="30 336.7 120.9 129.2" width="30">
                      <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                      <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                      <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                      <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                    </svg>
                  </div>
                  <div>
                    <div class="text-xs">GET IT ON</div>
                    <div class="text-base font-semibold font-sans -mt-1">Google Play</div>
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div
          className={`contact-button-facebook  ${facebookHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setFacebookHovered(true)}
          onMouseLeave={() => setFacebookHovered(false)}
        >
          {showButton && (
            <div>
              <a
                href={facebookLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center bg-[#0e7cf2] rounded-l-full py-2  px-5 text-white "
              >
                <BiLogoFacebook className=" facebook-icon" />

                <button className=" text-2xl  pl-2 mr-[1px]"> Facebook</button>

              </a>

            </div>
          )}
        </div>
        <div
          className={`contact-button-YouTube ${youTubeHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setYouTubeHovered(true)}
          onMouseLeave={() => setYouTubeHovered(false)}
        >
          {showButton && (
            <div>

              <a
                href={youtubeLink}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div class="flex w-[190px] h-14 rounded-l-full text-white    bg-red-600  items-center justify-center space-x-3">

                  <div class="">
                    <svg class="w-10 ml-2 fill-current " viewBox="0 0 24 24" >
                      <path
                        d="M21.9 5.9c-.2-.7-.7-1.2-1.4-1.4C18.3 4 12 4 12 4s-6.3 0-8.5.5c-.7.2-1.2.7-1.4 1.4C2 8.1 2 12 2 12s0 3.9.5 5.1c.2.7.7 1.2 1.4 1.4 2.2.5 8.5.5 8.5.5s6.3 0 8.5-.5c.7-.2 1.2-.7 1.4-1.4.5-1.2.5-5.1.5-5.1s0-3.9-.5-5.1zM9.5 15.5V8.5l6.5 3z" />
                    </svg>
                  </div>
                  <div>
                    <div class="text-2xl mr-4">YouTube</div>

                  </div>
                </div>
              </a>
            </div>
          )}
        </div>

      </div>
      <div className='xl:hidden lg:hidden md:hidden'>
        <div
          className={`top-[30%] fixed right-0   ${hovered ? 'hovered' : ''}`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {showButton && (
            <div>
              <div
                onClick={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className=" "
              >
                <FaWhatsapp className="bg-[#49C95A] text-white text-[50px] rounded-l-full py-2 pl-2 " />

                {/* <button className="contact-button-text text-2xl ">Contact Us</button> */}

              </div>

            </div>
          )}
        </div>
        <div
          className={`top-[40%] fixed right-0   ${playHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setPlayHovered(true)}
          onMouseLeave={() => setPlayHovered(false)}
        >
          {showButton && (
            <div>

              <a
                href={playstoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div class="rounded-l-full py-2 pl-4 pr-1 bg-black">
                  <div class="">
                    <svg viewBox="30 336.7 120.9 129.2" width="30">
                      <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                      <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                      <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                      <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div
          className={`top-[50%] fixed right-0   ${hovered ? 'hovered' : ''}`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {showButton && (
            <div>
              <a
                href={facebookLink}
                target="_blank"
                rel="noopener noreferrer"
                className=" "
              >
                <BiLogoFacebook className="bg-[#0e7cf2] text-white text-[50px] rounded-l-full py-1 pl-2 " />

              </a>

            </div>
          )}
        </div>
        <div
          className={`top-[60%] fixed right-0   ${playHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setPlayHovered(true)}
          onMouseLeave={() => setPlayHovered(false)}
        >
          {showButton && (
            <div>

              <a
                href={youtubeLink}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div class="rounded-l-full text-white  py-1 pl-2 pr-1 bg-red-600">
                  <div class=" ">
                    <svg class="w-9 h-10 fill-current " viewBox="0 0 24 24">
                      <path
                        d="M21.9 5.9c-.2-.7-.7-1.2-1.4-1.4C18.3 4 12 4 12 4s-6.3 0-8.5.5c-.7.2-1.2.7-1.4 1.4C2 8.1 2 12 2 12s0 3.9.5 5.1c.2.7.7 1.2 1.4 1.4 2.2.5 8.5.5 8.5.5s6.3 0 8.5-.5c.7-.2 1.2-.7 1.4-1.4.5-1.2.5-5.1.5-5.1s0-3.9-.5-5.1zM9.5 15.5V8.5l6.5 3z" />
                    </svg>
                  </div>
                  {/* <div>
                  <div class="text-xs">GET IT ON</div>
                  <div class="text-base font-semibold font-sans -mt-1">Google Play</div>
                </div> */}
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </BlogProvider>
  );
};
export default App;

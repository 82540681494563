import React, { useState, useEffect } from 'react';
import { BsDownload } from 'react-icons/bs'
import { Link } from 'react-router-dom';

const Download = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // Add scroll event listener on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <div className={` ${isScrolled ? ' buttonbg text-white py-3 px-3 rounded-lg buttom-color' : 'bg-white text-[#000F37] py-3 px-5 rounded-lg buttom-color'} `}>
      <div className={`hidden w-full lg:block ${isScrolled ? ' text-white' : 'text-[#000F37]'}`}>
        <div className="flex justify-between items-center space-x-2">
          <BsDownload />
          <Link to="https://play.google.com/store/apps/details?id=app.apnabillbook.com" target='_blank'>
          <button className="" >
            Download Now
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Download;

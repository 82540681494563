import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Googleplay = () => {

  const location = useLocation();
  const androidUrl = "https://play.google.com/store/apps/details?id=app.apnabillbook.com"; 
  const storeIdUrl = "https://double-freehold-401713.el.r.appspot.com/4ad3de84-bcaa-4bb2-9eb9-1846844e3314"; // Replace with your app's Google Play Store URL
  
  const isHomePage = location.pathname === '/';

  return (
    <div className='md:flex lg:flex justify-center items-center space-x-4'>

      <a
        href={androidUrl}
        target="_blank"
        rel="noopener noreferrer"
        className=""
      >
        <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
          <div class="mr-4 ">
            <svg viewBox="30 336.7 120.9 129.2" width="30">
              <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
              <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
              <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
              <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
            </svg>
          </div>
          <div>
            <div class="text-xs">GET IT ON</div>
            <div class="text-base font-semibold font-sans -mt-1">Google Play</div>
          </div>
        </div>
      </a>
      {!isHomePage && (
      <a
        href={storeIdUrl}
        target="_blank"
        rel="noopener noreferrer"
        className=""
      >
        <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
          <div class="mr-4 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="30"  fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg>


          </div>
          <div>
            {/* <div class="text-xs">BuyPrinter</div> */}
            <div class="text-base font-semibold font-sans -mt-1">Buy Printer</div>
          </div>
        </div>
      </a>
      )}
    </div>
  );
};

export default Googleplay;

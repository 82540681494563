import React, { useEffect, useState } from "react";
import background from "../../Assest/Banner/webbanner.png"


const Home = () => {

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  function addMetaData() {
    const title = "Apna Billbook - Best Shop & Bill Management App for Small Retailers and Restaurants";
    const description = "Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking.";
    const ogImage = "https://apnabillbook.com/twitter.jpg";
    const ogURL = "https://apnabillbook.com/";
    const twitterImage = "./twitter.jpg";
    // Set the title
    document.title = title;

    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = description.substring(0, 155) ?? description;

    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    addMetaData()
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className=" w-full" >
        <div className=" overflow-hidden relative  inset-0 ">
          <img src={background} alt="" className="absolute  inset-0 w-full h-screen object-cover " />
          <div class="relative text-center lg:mt-10 mt-2 ">
            <div >
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider text-center xl:mb-2">
                <h1 class="lg:space-y-8"> Simplify Your
                </h1>
              </h3>
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider font-bold text-center xl:mb-2">
                <h1> Business Management</h1>
              </h3>
              <h3 className="text-white text-[12px] uppercase tracking-wider text-center xl:mb-0 italic">with</h3>
              <h1 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider text-center xl:mb-2">
                Apna Billbook
              </h1>
              <div class="lg:mt-10  mt-3 px-5  lg:px-56  text-white tracking-wider text-base  lg:text-2xl ">

                <h2>
                  <span>
                    Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management,
                    and expense tracking.</span>
                </h2>

              </div>
              <div className="lg:mt-12  md:mt-4">
                <div className=" flex justify-center top-0 left-0 right-0 w-full z-10">
                  <div className="flex justify-center">
                    {/* <img
                      src={webpic}
                      alt=""
                      className={`hidden md:block transition-transform duration-200 ease-in-out transform-gpu ${scrollY > 0 ? '-translate-y-2' : ''
                        }`}
                    /> */}
                    <div className="mt-2 lg:block xl:block hidden tex
                    items-center lg:h-200 mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                      <div className="h-full translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                        <video autoPlay muted loop className="h-full w-full object-cover rounded-3xl"
                          poster="https://app.apnabillbook.com/uploads/1697028244740download.png"
                        >
                          <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <div className="mt-5 lg:hidden xl:hidden px-1 mb-5 text-center
                    items-center lg:h-200  -translate-x-2 -rotate-0 rounded-3xl bg-white p-1 ml-5 mr-1.5">
                      <div className="h-full translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                        <video autoPlay muted loop className="h-full w-full object-cover rounded-3xl"
                          poster="https://app.apnabillbook.com/uploads/1697028244740download.png"
                        >
                          <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

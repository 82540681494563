import React, { useState, useEffect } from "react";

import Getstarted from '../Section/Getstarted'
import PageTitle from "../Section/PageTitel/Pagetitle";

import rapidBilling from '../../Assest/Banner/bill.png'
import ContactUsFooter from "../Footer/ContactUsFooter";
import FeaturePreviewCard from "./FeaturePreviewCard";
import bill from '../../Assest/icons/bill1.png'
import tax from '../../Assest/icons/tax.png'
import storeFront from '../../Assest/icons/store.png'
import expand from '../../Assest/icons/viral-marketing.png'
import customerAccess from '../../Assest/icons/shopping-bag.png'
import inventory from '../../Assest/icons/inventory.png'
import inventoryAnalyse from '../../Assest/icons/stock-keeping-unit.png'
import performance from '../../Assest/icons/performance.PNG'
import debt from '../../Assest/icons/bill1.png'
import money from '../../Assest/icons/money.png'
import operator from '../../Assest/icons/operator.png'
import gst from '../../Assest/icons/gst.PNG'
import realTime from '../../Assest/icons/real-time.png'
import sales from '../../Assest/icons/sales.PNG'
import expenseCalculator from '../../Assest/icons/calculatorExpense.PNG'



const Features = () => {
  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };
  const [fix, setFix] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);

  const imageBaseUrl = "https://app.apnabillbook.com/uploads/";
  const featureDataQuickBilling =
  {
    data: [
      {
        title: "Rapid Invoicing",
        description: "The Quick Billing System enables businesses to generate invoices swiftly. With just a few clicks, you can create professional invoices, saving valuable time and ensuring timely billing for your products or services.",
        icon: rapidBilling,
        image: "https://app.apnabillbook.com/uploads/1696754355938Untitled%20design%20-%2025.png",
        id: 1,
      },
      {
        title: "Personalized Branding Thermal & A4 Invoice's",
        description: "Share & Print Thermal & A4 Customize invoices with your company's branding, including logos and colors. This feature gives your invoices a polished and consistent look, reinforcing your brand identity and professionalism.",
        icon: bill,
        id: 3,
        image: "https://app.apnabillbook.com/uploads/1696755229713Untitled design - 4.png",
      },
      {
        title: "GST Compliance",
        description: "The Quick Billing System ensures compliance with GST regulations, making it easy to handle tax-related complexities. It automates the GST calculations and reporting, reducing the risk of errors and simplifying tax compliance for your business.",
        icon: tax,
        id: 4,
        image: "https://app.apnabillbook.com/uploads/1696755158412Untitled design - 42.png  ",
      }
    ],
    videoLink: "aZgxVvN1GdA",
    title: "Quick Billing",
  };

  const featureDataOnline =
  {
    data: [
      {
        title: "Digital Storefront Creation",
        description: "Online Store feature allows you to establish a digital storefront quickly and easily. It empowers your business to have an online presence, where customers can browse and purchase your products or services from anywhere, 24/7.",
        icon: storeFront,
        image: imageBaseUrl + '1696755454533Untitled design - 1 3.png',
        id: 1,
      },
      {
        title: "Expanded Customer Reach",
        description: "With an Individual Online Store, you can reach a wider audience beyond your physical location. This feature enables you to tap into the growing digital market, attracting customers who prefer online shopping and increasing your business's visibility.",
        icon: expand,
        id: 2,
        image: imageBaseUrl + '1696755817341Untitled design - 40.png',
      },
      {
        title: "Modern Shopping Experience",
        description: "Provide a modern and convenient shopping experience for your customers. They can effortlessly browse your product catalog, make selections, and place orders online, enhancing customer engagement and satisfaction while aligning with contemporary shopping trends.",
        icon: customerAccess,
        id: 3,
        image: imageBaseUrl + '1696755905860Untitled design - 11.png',
      },
    ],
    videoLink: "aZgxVvN1GdA",
    title: "Online Store",
  };

  const featureDataInventory =
  {
    data: [
      {
        title: "Effortless Product Organization",
        description: "Inventory Management simplifies product organization by allowing you to categorize items and track variants. This feature helps you maintain a well-organized product catalog, making it easier to find and manage your products.",
        icon: inventory,
        image: imageBaseUrl + '1696756082049Untitled design - 36.png',
        id: 1,
      },
      {
        title: "Real-Time Stock Monitoring",
        description: "Stay informed about your stock levels in real-time. With Inventory Management, you can monitor the availability of products, receive notifications about low stock, and make informed decisions regarding restocking or inventory adjustments.",
        icon: inventoryAnalyse,
        id: 2,
        image: imageBaseUrl + '1696756237661Untitled design - 28.png',
      },
      {
        title: "Historical Data Access",
        description: "Access to historical data is crucial for business planning. Apna Billbook's Inventory Management feature provides you with valuable insights by allowing you to review past inventory data. This historical perspective aids in making informed restocking decisions and optimizing your product offerings.",
        icon: performance,
        id: 3,
        image: imageBaseUrl + '1696756375236Untitled design - 19.png',
      },
    ],
    videoLink: "aZgxVvN1GdA",
    title: "Inventory Management",
  };

  const featureDataCustomer =
  {
    data: [
      {
        title: "Efficient Debt/Ledger/Udhaar Khata",
        description: "Simplifies tracking and managing customer Debt/Udhaar Khatabook, ensuring you stay on top of outstanding payments. By promptly recording and reminding customers about their dues, you can improve your cash flow and overall financial stability.",
        icon: debt,
        image: imageBaseUrl + '1696756506001Untitled design - 18.png',
        id: 1,
      },
      {
        title: "Cash Flow Management",
        description: "By promptly recording and reminding customers about their dues, you can improve your cash flow and overall financial stability. By promptly recording and reminding customers about their dues, you can improve your cash flow and overall financial stability.",
        icon: money,
        id: 2,
        image: imageBaseUrl + '1696756506003Untitled design - 34.png',
      },
      {
        title: "Strengthened Customer Relationships",
        description: "Maintaining a clear Udhaar ledger fosters transparency and trust, leading to healthier, long-term customer relationships. Customer feedback provides valuable insights, enabling you to enhance your products or services based on real customer opinions.",
        icon: operator,
        id: 3,
        image: imageBaseUrl + '1696756506005Untitled design - 7.png',
      },
    ],

    videoLink: "aZgxVvN1GdA",
    title: "Customer Udhaar / Khatabook",
  };


  const featureDataExpenses =
  {
    data: [
      {
        title: "Efficient Expense Tracking",
        description: "Streamline expense tracking with intuitive interface.Easily record and categorize expenses, ensuring accurate financial records.Gain a comprehensive view of your business's spending patterns",
        icon: expenseCalculator,
        image: imageBaseUrl + '1696757066747Untitled design - 33.png',
        id: 1,
      },
      {
        title: "Financial Control and Savings",
        description: "Maintain better financial control by identifying unnecessary expenditures. Spot cost- saving opportunities and make informed decisions to maximize profitability. Simplify tax preparation by having organized and categorized expense records readily available",
        icon: money,
        id: 2,
        image: imageBaseUrl + '1696757066747Untitled design - 33.png',
      },
      {
        title: "Time-Saving Automation",
        description: "Automate the expense management process, reducing manual data entry. Save time on administrative tasks, allowing you to focus more on growing your business.Ensure compliance with tax regulations and have a clear audit trail for expense - related transactions",
        icon: realTime,
        id: 3,
        image: imageBaseUrl + '1696757066747Untitled design - 33.png',
      },
    ],
    videoLink: "aZgxVvN1GdA",
    title: "Expense Management",
  };

  const featureDataReports =
  {
    data: [
      {
        title: "Comprehensive Business Insights",
        description: "Access detailed reports for a full view of financial performance.Make informed strategic decisions based on data- driven insights. Identify areas for improvement and growth opportunities. Gain a competitive edge with actionable analytics.",
        icon: expenseCalculator,
        image: imageBaseUrl + '1696757199609Untitled design - 14.png',
        id: 1,
      },
      {
        title: "Performance Evaluation",
        description: "Evaluate strategies and find growth opportunities using historical data. Monitor the impact of pricing and marketing changes. Identify top-performing products and services. Gain valuable insights into your business's performance.",
        icon: sales,
        id: 2,
        image: imageBaseUrl + '1696757199611Untitled design - 28.png',
      },
      {
        title: "GST Compliance and Financial Planning",
        description: "Ensure effortless GST compliance with automated reporting.Simplify tax returns with accurate GST reports.Use financial analytics for effective tax planning. Stay on top of your business's financial health.",
        icon: gst,
        id: 3,
        image: imageBaseUrl + '1696757199613Untitled design - 39.png',
      },
    ],
    videoLink: "aZgxVvN1GdA",
    title: "Reports & Analytics",
  };



  return (
    <div className={fix ? "w-full  bg-white" : " bg-white"}>
      <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
        }`}>
        <PageTitle
          activeMenu="Features"
        />
      </div>
      <div className="text-center py-10 xl:px-10 px-5 xl:text-[40px] text-[20px] font-bold">
        Smart Features for Shop's Efficiency and Growth
      </div>
      <FeaturePreviewCard featureData={featureDataQuickBilling} />
      <FeaturePreviewCard featureData={featureDataOnline} />
      <FeaturePreviewCard featureData={featureDataInventory} />
      <FeaturePreviewCard featureData={featureDataCustomer} />
      <FeaturePreviewCard featureData={featureDataExpenses} />
      <FeaturePreviewCard featureData={featureDataReports} />
      <Getstarted />
      <ContactUsFooter />
    </div>
  )
}

export default Features
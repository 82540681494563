import React, { useState } from 'react'
import Googleplay from '../Buttom/Googleplay'
import GetlinkHome from '../AppgetLinkdownload/Getlink_Home';

const DownloadButtom = () => {
  return (
    <>
      <div className="px-5">
        <div className="lg:mt-5 mt-8
         ">
          <div class="flex justify-center mb-5">
            <div class="">
              <GetlinkHome />
            </div>
          </div>
          <div className=" flex justify-center mb-8" >
            <Googleplay />
          </div>
        </div>
      </div>
    </>
  )
}

export default DownloadButtom